<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import Util from "@/mixins/util";
export default {
  mixins: [Util],
  async created() {
    let url = `${this.apiURL}/liff-ad-pics`;
    let data = await fetch(url);
    data = await data.json();
    let images = {};
    data.data.forEach((image) => {
      images[image.filename] = image.url;
    });

    url = `${this.apiURL}/popup-pics`;
    data = await fetch(url);
    data = await data.json();
    data.data.forEach((image) => {
      images[image.filename] = image.url;
    });

    this.$store.commit("setImages", images);
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+TC:300,400,700|Oswald:300,400,700|Rubik:300,300i,400,700&display=swap&subset=chinese-traditional");
body {
  margin: 0;
  #app {
    min-height: 100vh;
  }
  ::v-deep button,
  ::v-deep input {
    outline: none !important;
  }
}
</style>
