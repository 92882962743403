import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/policy",
    component: () => import("../views/Policy.vue"),
  },
  {
    path: "/detailCheck",
    component: () => import("../views/DetailCheck.vue"),
  },
  {
    path: "/goodluck/day",
    component: () => import("../views/DetailCheck.vue"),
  },
  {
    path: "/input",
    component: () => import("../views/Input.vue"),
  },
  {
    path: "/birth",
    component: () => import("../views/Birth.vue"),
  },
  {
    path: "/goodluck/birth",
    component: () => import("../views/Birth.vue"),
  },
  {
    path: "/peep",
    component: () => import("../views/Peep.vue"),
  },
  {
    path: "/goodluck/year",
    component: () => import("../views/GoodluckYear.vue"),
  },
  {
    path: "/goodluck/month",
    component: () => import("../views/GoodluckMonth.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
