const liff = window.liff;
export default {
  data() {
    return {
      // apiURL: "https://line.kenchenisme.com/api",
      apiURL: "https://api.murphysday.com/api",
      profile: null,
    };
  },
  async created() {
    this.profile = this.$store.state.profile;
    this.setTitle();
  },

  methods: {
    async setLiff(liffId) {
      await liff.init({
        liffId,
      });
      if (window.location.host.includes("localhost")) {
        this.profile.userId = "Ud5e0f543cbe74d9bd4d0fd723e9967ed";
        // this.profile.userId = "U5e2a8294d2d7a413532836dc2502df1d";
      }
      try {
        if (!this.profile.userId) {
          if (liff.isLoggedIn()) {
            let profile = await liff.getProfile();
            this.$store.commit("setProfile", profile);
          } else {
            await liff.login();
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    setTitle() {
      document.title = this.title || "☆";
      // document.title = "☆";
    },
    async setState(type) {
      let url = `${this.apiURL}/notifications/lineID/${this.profile.userId}?type=${type}`;
      await fetch(url, { method: "post" });
    },
    async getState(type) {
      let url = `${this.apiURL}/notifications/lineID/${this.profile.userId}?type=${type}`;
      let data = await fetch(url);
      data = await data.json();
      return data;
    },
  },
};
