import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    images: [],
    profile: {
      userId: "",
    },
  },
  mutations: {
    setImages(state, images) {
      state.images = images;
    },
    setProfile(state, profile) {
      state.profile = profile;
    },
  },
  actions: {},
  modules: {},
});
